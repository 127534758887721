var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"CfbParameter7"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"_参数07_1")]),_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])]),_vm._m(0),_c('div',{staticClass:"content flex"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button1"},[_c('div',{staticClass:"button1_word",on:{"click":function($event){return _vm.toCompon(3)}}},[_vm._v("风量软测量")])]),_c('div',{staticClass:"button2"},[_c('div',{staticClass:"button2_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.SELFL2,
                '按钮',
                'SELFL2',
                _vm.infoList.SELFL2_node_string
              )}}},[_vm._v(" "+_vm._s("SELFL2" in _vm.infoList ? _vm.infoList.SELFL2 ? "软测量" : "实际测量" : 0)+" ")])])]),_c('div',{staticClass:"shadow shadow1"},[_c('div',{staticClass:"flex"},[_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H01-TC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.ECFTC,
                    'H01-TC',
                    'ECFTC',
                    _vm.infoList.ECFTC_node_string
                  )}}},[_vm._v(" "+_vm._s("ECFTC" in _vm.infoList ? _vm.infoList.ECFTC : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H01-K1")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.FLXS3,
                    'H01-K1',
                    'FLXS3',
                    _vm.infoList.FLXS3_node_string
                  )}}},[_vm._v(" "+_vm._s("FLXS3" in _vm.infoList ? _vm.infoList.FLXS3 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H01-PZ1")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.FLCS3,
                    'H01-PZ1',
                    'FLCS3',
                    _vm.infoList.FLCS3_node_string
                  )}}},[_vm._v(" "+_vm._s("FLCS3" in _vm.infoList ? _vm.infoList.FLCS3 : 0)+" ")])])]),_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H01-PV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'ECFL',
                    _vm.infoList.ECFL_node_string,
                    'ECFLVV1',
                    '二次风量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.ECFL,
                    'ECFL',
                    _vm.infoList.ECFL_node_string,
                    'ECFLVV1'
                  )}}},[_vm._v(" "+_vm._s("ECFL" in _vm.infoList ? _vm.infoList.ECFL : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H01-K2")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.FLXS4,
                    'H01-K2',
                    'FLXS4',
                    _vm.infoList.FLXS4_node_string
                  )}}},[_vm._v(" "+_vm._s("FLXS4" in _vm.infoList ? _vm.infoList.FLXS4 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H01-PZ2")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.FLCS4,
                    'H01-PZ2',
                    'FLCS4',
                    _vm.infoList.FLCS4_node_string
                  )}}},[_vm._v(" "+_vm._s("FLCS4" in _vm.infoList ? _vm.infoList.FLCS4 : 0)+" ")])])])])]),_c('div',{staticClass:"shadow2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H02-JBECFL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.MEFXS,
                'H02-JBECFL',
                'MEFXS',
                _vm.infoList.MEFXS_node_string
              )}}},[_vm._v(" "+_vm._s("MEFXS" in _vm.infoList ? _vm.infoList.MEFXS : 0)+" ")])])]),_c('div',{staticClass:"shadow shadow3 flex"},[_c('div',[_c('div',{staticClass:"shadow3_word"},[_vm._v("煤量二次风")]),_c('div',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H03-MLK")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.MEFXS,
                    'H03-MLK',
                    'MEFXS',
                    _vm.infoList.MEFXS_node_string
                  )}}},[_vm._v(" "+_vm._s("MEFXS" in _vm.infoList ? _vm.infoList.MEFXS : 0)+" ")])])])]),_c('div',{staticClass:"location1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H03-CYK")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.TZ1,
                  'H03-CYK',
                  'TZ1',
                  _vm.infoList.TZ1_node_string
                )}}},[_vm._v(" "+_vm._s("TZ1" in _vm.infoList ? _vm.infoList.TZ1 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H03-0T")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'GMZJAV',
                  _vm.infoList.GMZJAV_node_string,
                  'GMZJAVVV1',
                  '给煤量调整二次风目标值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.GMZJAV,
                  'GMZJAV',
                  _vm.infoList.GMZJAV_node_string,
                  'GMZJAVVV1'
                )}}},[_vm._v(" "+_vm._s("GMZJAV" in _vm.infoList ? _vm.infoList.GMZJAV : 0)+" ")])])])]),_c('div',{staticClass:"shadow shadow4 flex"},[_c('div',[_c('div',{staticClass:"shadow4_word"},[_vm._v("氧量二次风")]),_c('div',{staticClass:"shadow4_bg"},[_c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("H04-02PV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PV',
                    _vm.infoList.XK0Q_node_string,
                    'XK0Q_PVVV1',
                    'XK0L测量值',
                    'XK0Q'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.XK0Q.PV,
                    'PV',
                    _vm.infoList.XK0Q_node_string,
                    'XK0Q_PVVV1'
                  )}}},[_vm._v(" "+_vm._s("XK0Q" in _vm.infoList ? _vm.infoList.XK0Q.PV : 0)+" ")])]),_c('div',{staticClass:"buttonx float2",style:({
                background:
                  _vm.infoList.XK0Q && !_vm.infoList.XK0Q.SP ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.XK0Q
                  ? _vm.toDetail(
                      1,
                      'XK0Q',
                      _vm.infoList.XK0Q_node_string,
                      '',
                      '烟气含氧量调节先控'
                    )
                  : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"button"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button3"},[_c('div',{staticClass:"button3_word",style:({
                    background: _vm.infoList.SEL_O2 == 1 ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){return _vm.toIpt(
                      '1',
                      'H04-YTLCL1',
                      'SEL_O2',
                      _vm.infoList.SEL_O2_node_string,
                      'true'
                    )}}},[_vm._v(" H04-YTLCL1 ")])]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AT101AD_B',
                    _vm.infoList.AT101AD_B_node_string,
                    'AT101AD_BVV1',
                    '烟气含氧量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.AT101AD_B,
                    'AT101AD_B',
                    _vm.infoList.AT101AD_B_node_string,
                    'AT101AD_BVV1'
                  )}}},[_vm._v(" "+_vm._s("AT101AD_B" in _vm.infoList ? _vm.infoList.AT101AD_B : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"button3 top"},[_c('div',{staticClass:"button3_word",style:({
                    background: _vm.infoList.SEL_O2 == 2 ? '#2AFC30' : 'red',
                  }),on:{"click":function($event){return _vm.toIpt(
                      '1',
                      'H04-YTLCL2',
                      'SEL_O2',
                      _vm.infoList.SEL_O2_node_string,
                      'true'
                    )}}},[_vm._v(" H04-YTLCL2 ")])]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AT101BD_B',
                    _vm.infoList.AT101BD_B_node_string,
                    'AT101BD_BVV1',
                    '烟气含氧量'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.AT101BD_B,
                    'AT101BD_B',
                    _vm.infoList.AT101BD_B_node_string,
                    'AT101BD_BVV1'
                  )}}},[_vm._v(" "+_vm._s("AT101BD_B" in _vm.infoList ? _vm.infoList.AT101BD_B : 0)+" ")])])])]),_c('div',[_c('div',{staticClass:"location2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H04-JBSP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.C_O2,
                    'H04-JBSP',
                    'C_O2',
                    _vm.infoList.C_O2_node_string
                  )}}},[_vm._v(" "+_vm._s("C_O2" in _vm.infoList ? _vm.infoList.C_O2 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H04-02SP")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'SP',
                    _vm.infoList.XK0K_node_string,
                    'XK0K_SPVV1',
                    '左1烟气挡板调节',
                    'XK0K'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.XK0K.SP,
                    'SP',
                    _vm.infoList.XK0K_node_string,
                    'XK0K_SPVV1'
                  )}}},[_vm._v(" "+_vm._s("XK0K" in _vm.infoList ? _vm.infoList.XK0K.SP : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H04-0T")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'AV',
                    _vm.infoList.XK0Q_node_string,
                    'XK0Q_AVVV1',
                    'XK0L总输出',
                    'XK0Q'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.XK0Q.AV,
                    'AV',
                    _vm.infoList.XK0Q_node_string,
                    'XK0Q_AVVV1'
                  )}}},[_vm._v(" "+_vm._s("XK0Q" in _vm.infoList ? _vm.infoList.XK0Q.AV : 0)+" ")])])]),_c('div',{staticClass:"location2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H04-02K")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.KO2,
                    'H04-JBSP',
                    'KO2',
                    _vm.infoList.KO2_node_string
                  )}}},[_vm._v(" "+_vm._s("KO2" in _vm.infoList ? _vm.infoList.KO2 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H04-HZYZ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.O2HZYZ,
                    'H04-JBSP',
                    'O2HZYZ',
                    _vm.infoList.O2HZYZ_node_string
                  )}}},[_vm._v(" "+_vm._s("O2HZYZ" in _vm.infoList ? _vm.infoList.O2HZYZ : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H04-02TC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.TCO2,
                    'H04-JBSP',
                    'TCO2',
                    _vm.infoList.TCO2_node_string
                  )}}},[_vm._v(" "+_vm._s("TCO2" in _vm.infoList ? _vm.infoList.TCO2 : 0)+" ")])])])])]),_c('div',{staticClass:"shadow shadow5 flex"},[_c('div',[_c('div',{staticClass:"shadow5_word"},[_vm._v("床温二次风")]),_c('div',{staticClass:"shadow5_bg"},[_c('div',{staticClass:"float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("H05-MCWPV")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                    2,
                    'PV',
                    _vm.infoList.RSF0E_node_string,
                    'RSF0E_PVVV1',
                    'RSF0E测量值',
                    'RSF0E'
                  )},"dblclick":function($event){return _vm.Cclick(
                    _vm.infoList.RSF0E.PV,
                    'PV',
                    _vm.infoList.RSF0E_node_string,
                    'RSF0E_PVVV1'
                  )}}},[_vm._v(" "+_vm._s("RSF0E" in _vm.infoList ? _vm.infoList.RSF0E.PV : 0)+" ")])]),_c('div',{staticClass:"buttonr float2",style:({
                background:
                  _vm.infoList.RSF0E && !_vm.infoList.RSF0E.SP ? '#2AFC30' : 'red',
              }),on:{"click":function($event){_vm.infoList.RSF0E
                  ? _vm.toDetail(
                      3,
                      'RSF0E',
                      _vm.infoList.RSF0E_node_string,
                      '',
                      '床温二次风软伺服'
                    )
                  : ''}}},[_vm._v(" R ")])]),_c('div',{staticClass:"float3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("H05-CWHL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ECF_CWMAXH,
                  'H05-CWHL',
                  'ECF_CWMAXH',
                  _vm.infoList.ECF_CWMAXH_node_string
                )}}},[_vm._v(" "+_vm._s("ECF_CWMAXH" in _vm.infoList ? _vm.infoList.ECF_CWMAXH : 0)+" ")])])]),_c('div',{staticClass:"location2"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H05-CWSP")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SP',
                  _vm.infoList.RSF0E_node_string,
                  'RSF0E_SPVV1',
                  'RSF0E设定值',
                  'RSF0E'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.RSF0E.SP,
                  'SP',
                  _vm.infoList.RSF0E_node_string,
                  'RSF0E_SPVV1'
                )}}},[_vm._v(" "+_vm._s("RSF0E" in _vm.infoList ? _vm.infoList.RSF0E.SP : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H05-0T")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'AV',
                  _vm.infoList.RSF0E_node_string,
                  'RSF0E_AVVV1',
                  'RSF0E输出值',
                  'RSF0E'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.RSF0E.AV,
                  'AV',
                  _vm.infoList.RSF0E_node_string,
                  'RSF0E_AVVV1'
                )}}},[_vm._v(" "+_vm._s("RSF0E" in _vm.infoList ? _vm.infoList.RSF0E.AV : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H05-CWLL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ECF_CWMAXL,
                  'H05-CWLL',
                  'ECF_CWMAXL',
                  _vm.infoList.ECF_CWMAXL_node_string
                )}}},[_vm._v(" "+_vm._s("ECF_CWMAXL" in _vm.infoList ? _vm.infoList.ECF_CWMAXL : 0)+" ")])])])]),_c('div',{staticClass:"shadow shadow6 flex"},[_c('div',[_c('div',{staticClass:"shadow6_word"},[_vm._v("氧含量优化模型")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-TOP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.TOP,
                  'H06-TOP',
                  'TOP',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string
                )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.TOP : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-SOP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.SOP10,
                  'H06-SOP',
                  'SOP10',
                  _vm.infoList.SOP10_node_string
                )}}},[_vm._v(" "+_vm._s("SOP10" in _vm.infoList ? _vm.infoList.SOP10 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-EOP")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.EOP10,
                  'H06-EOP',
                  'EOP10',
                  _vm.infoList.EOP10_node_string
                )}}},[_vm._v(" "+_vm._s("EOP10" in _vm.infoList ? _vm.infoList.EOP10 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-SFK")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.K11,
                  'H06-SFK',
                  'K11',
                  _vm.infoList.K11_node_string
                )}}},[_vm._v(" "+_vm._s("K11" in _vm.infoList ? _vm.infoList.K11 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-OH")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHZL_H',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string,
                  'BCS1HVV1',
                  '氧量优化目标上限',
                  'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_H,
                  'YHZL_H',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string,
                  'BCS1HVV1'
                )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL .YHZL_H : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-TC(m)")]),_c('div',{staticClass:"column2",on:{"click":function($event){_vm.toIpt(
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHJG,
                  'H06-TC(m)',
                  'YHJG',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string
                )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHJG : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-SYT(m)")]),_c('div',{staticClass:"column3"},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL .YHJGSYSJ : 0)+" ")])])]),_c('div',{staticClass:"location1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("常规负荷")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(_vm.infoList.F0, '常规负荷', 'F0', _vm.infoList.F0_node_string)}}},[_vm._v(" "+_vm._s("F0" in _vm.infoList ? _vm.infoList.F0 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-SOPSJ")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'SOP',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string,
                  'SOP1VV1',
                  '优化步长',
                  'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.SOP,
                  'SOP',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string,
                  'SOP1VV1'
                )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.SOP : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-EOPSJ")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'EOP',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string,
                  'EOP1VV1',
                  '优化精度',
                  'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.EOP,
                  'EOP',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string,
                  'EOP1VV1'
                )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.EOP : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-EFK")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.K12,
                  'H06-EFK',
                  'K12',
                  _vm.infoList.K12_node_string
                )}}},[_vm._v(" "+_vm._s("K12" in _vm.infoList ? _vm.infoList.K12 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-0L")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHZL_L',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string,
                  'BCS1LVV1',
                  '氧量优化目标下限',
                  'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.YHZL_L,
                  'YHZL_L',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string,
                  'BCS1LVV1'
                )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL .YHZL_L : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-0T")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'YHZL',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,
                  'AV1VV1',
                  '优化增量输出',
                  'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL,
                  'YHZL',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,
                  'AV1VV1'
                )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.YHZL : 0)+" ")])]),_vm._m(1)])])]),_c('div',{staticClass:"middle flex"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"button1"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.SEL71,
                '按钮',
                'SEL71',
                _vm.infoList.SEL71_node_string
              )}}},[_vm._v(" "+_vm._s("SEL71" in _vm.infoList ? (_vm.infoList.SEL71 ? "投用" : "切除") : 0)+" ")])]),_c('div',{staticClass:"button2"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.O2_ECF_QY,
                '按钮',
                'O2_ECF_QY',
                _vm.infoList.O2_ECF_QY_node_string
              )}}},[_vm._v(" "+_vm._s("O2_ECF_QY" in _vm.infoList ? _vm.infoList.O2_ECF_QY ? "投用" : "切除" : 0)+" ")])]),_c('div',{staticClass:"word1"},[_c('div',{staticClass:"line1"},[_vm._v("H04-02SPH")]),_c('div',{staticClass:"line2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.O2SPH,
                'H04-02SPH',
                'O2SPH',
                _vm.infoList.O2SPH_node_string
              )}}},[_vm._v(" "+_vm._s("O2SPH" in _vm.infoList ? _vm.infoList.O2SPH : 0)+" ")])]),_c('div',{staticClass:"word2"},[_c('div',{staticClass:"line1"},[_vm._v("H04-02SPL")]),_c('div',{staticClass:"line2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.O2SPL,
                'H04-02SPL',
                'O2SPL',
                _vm.infoList.O2SPL_node_string
              )}}},[_vm._v(" "+_vm._s("O2SPL" in _vm.infoList ? _vm.infoList.O2SPL : 0)+" ")])]),_c('div',{staticClass:"button3"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.ECF_CWSEL,
                '按钮',
                'ECF_CWSEL',
                _vm.infoList.ECF_CWSEL_node_string
              )}}},[_vm._v(" "+_vm._s("ECF_CWSEL" in _vm.infoList ? _vm.infoList.ECF_CWSEL ? "投用" : "切除" : 0)+" ")])]),_c('div',{staticClass:"button4"},[_c('div',{staticClass:"button4_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr__.BCSYHQY,
                '按钮',
                'BCSYHQY',
                _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr___node_string
              )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr__" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr__.BCSYHQY ? "优化投用" : "优化切除" : '优化切除')+" ")])]),_c('div',{staticClass:"button5"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.RESET,
                '按钮',
                'RESET',
                _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL_node_string
              )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__ZDBL.RESET ? "强制优化" : "自动优化" : 0)+" ")])])]),_c('div',{staticClass:"two"},[_c('div',{staticClass:"shadow shadow1 float1"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H07-SP")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'FGFSP_B2',
                  _vm.infoList.FGFSP_B2_node_string,
                  'FGFSP_B2VV1',
                  '风量目标值'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.FGFSP_B2,
                  'H07-SP',
                  'FGFSP_B2',
                  _vm.infoList.FGFSP_B2_node_string
                )}}},[_vm._v(" "+_vm._s("FGFSP_B2" in _vm.infoList ? _vm.infoList.FGFSP_B2 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H07-SPH")]),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":String(_vm.infoList.ECFSPH),"placement":"top"}},[_c('div',{staticClass:"column2 textov",on:{"click":function($event){return _vm.toIpt(
                    _vm.infoList.ECFSPH,
                    'H07-SPH',
                    'ECFSPH',
                    _vm.infoList.ECFSPH_node_string
                  )}}},[_vm._v(" "+_vm._s("ECFSPH" in _vm.infoList ? _vm.infoList.ECFSPH : 0)+" ")])])],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H07-SPL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.ECFSPL,
                  'H07-SPL',
                  'ECFSPL',
                  _vm.infoList.ECFSPL_node_string
                )}}},[_vm._v(" "+_vm._s("ECFSPL" in _vm.infoList ? _vm.infoList.ECFSPL : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H07-TC3")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.TECFFOP,
                  'H07-TC3',
                  'TECFFOP',
                  _vm.infoList.TECFFOP_node_string
                )}}},[_vm._v(" "+_vm._s("TECFFOP" in _vm.infoList ? _vm.infoList.TECFFOP : 0)+" ")])])]),_c('div',{staticClass:"shadow shadow2"},[_c('div',{staticClass:"shadow2_word"},[_vm._v("优化条件")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-ZQ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.BYMNL06,
                  'H06-ZQ',
                  'BYMNL06',
                  _vm.infoList.BYMNL06_node_string
                )}}},[_vm._v(" "+_vm._s("BYMNL06" in _vm.infoList ? _vm.infoList.BYMNL06 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-BHFD")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.BYMNL07,
                  'H06-BHFD',
                  'BYMNL07',
                  _vm.infoList.BYMNL07_node_string
                )}}},[_vm._v(" "+_vm._s("BYMNL07" in _vm.infoList ? _vm.infoList.BYMNL07 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-DDSJ")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.BYMNL08,
                  'H06-DDSJ',
                  'BYMNL08',
                  _vm.infoList.BYMNL08_node_string
                )}}},[_vm._v(" "+_vm._s("BYMNL08" in _vm.infoList ? _vm.infoList.BYMNL08 : 0)+" ")])])]),_c('div',{staticClass:"shadow shadow3"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-0E")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'OBCS',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,
                  'OE1VV1',
                  '优化过程上次值',
                  'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.OBCS,
                  'OBCS',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,
                  'OE1VV1'
                )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.OBCS : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column1"},[_vm._v("H06-NE")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'NBCS',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,
                  'NE1VV1',
                  '优化过程本次值',
                  'BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.NBCS,
                  'NBCS',
                  _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL_node_string,
                  'NE1VV1'
                )}}},[_vm._v(" "+_vm._s("BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL" in _vm.infoList ? _vm.infoList.BCSYH_SHM1__p__BCSYHn__ll__1__rr____p__DXBL.NBCS : 0)+" ")])])])]),_c('div',{staticClass:"three"},[_c('div',{staticClass:"buttonx button1",style:({
            background:
              _vm.infoList.XK0E && !_vm.infoList.XK0E.SP ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK0E
              ? _vm.toDetail(
                  1,
                  'XK0E',
                  _vm.infoList.XK0E_node_string,
                  '',
                  '二次风变频调节'
                )
              : ''}}},[_vm._v(" X ")]),_c('div',{staticClass:"buttonx button2",style:({
            background:
              _vm.infoList.XK0D && !_vm.infoList.XK0D.SP ? '#2AFC30' : 'red',
          }),on:{"click":function($event){_vm.infoList.XK0D
              ? _vm.toDetail(
                  1,
                  'XK0D',
                  _vm.infoList.XK0D_node_string,
                  '',
                  '二次风挡板调节'
                )
              : ''}}},[_vm._v(" X ")])]),_c('div',{staticClass:"four"},[_c('div',{staticClass:"shadow shadow4 flex"},[_c('div',{staticClass:"column1"},[_vm._v("H08-IHL")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.BYMNL43,
                'H08-IHL',
                'BYMNL43',
                _vm.infoList.BYMNL43_node_string
              )}}},[_vm._v(" "+_vm._s("BYMNL43" in _vm.infoList ? _vm.infoList.BYMNL43 : 0)+" ")])]),_c('div',{staticClass:"button3"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.ECFSPPVSEL,
                '按钮',
                'ECFSPPVSEL',
                _vm.infoList.ECFSPPVSEL_node_string
              )}}},[_vm._v(" "+_vm._s("ECFSPPVSEL" in _vm.infoList ? _vm.infoList.ECFSPPVSEL ? "投用" : "切除" : 0)+" ")])])]),_c('div',{staticClass:"five"},[_c('div',{staticClass:"word"},[_vm._v("氧量二次风执行机构")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"buttonr button4",style:({
              background:
                _vm.infoList.RSF07 && !_vm.infoList.RSF07.SP ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.RSF07
                ? _vm.toDetail(
                    3,
                    'RSF07',
                    _vm.infoList.RSF07_node_string,
                    '',
                    '二次风电流纠偏'
                  )
                : ''}}},[_vm._v(" R ")]),_c('div',{staticClass:"plus"}),_c('div',{staticClass:"buttonr button5",style:({
              background:
                _vm.infoList.QK0C && !_vm.infoList.QK0C.TS ? '#2AFC30' : 'red',
            }),on:{"click":function($event){_vm.infoList.QK0C
                ? _vm.toDetail(
                    2,
                    'QK0C',
                    _vm.infoList.QK0C_node_string,
                    '',
                    'QK0C设定值'
                  )
                : ''}}},[_vm._v(" K ")]),_c('div',{staticClass:"button6"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.QK0CSEL,
                  '按钮',
                  'QK0CSEL',
                  _vm.infoList.QK0CSEL_node_string
                )}}},[_vm._v(" "+_vm._s("QK0CSEL" in _vm.infoList ? _vm.infoList.QK0CSEL ? "投用" : "切除" : 0)+" ")])])]),_c('div',{staticClass:"button7"},[_c('div',{staticClass:"button_word",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.RSF07SEL,
                '按钮',
                'RSF07SEL',
                _vm.infoList.RSF07SEL_node_string
              )}}},[_vm._v(" "+_vm._s("RSF07SEL" in _vm.infoList ? _vm.infoList.RSF07SEL ? "电流纠偏投用" : "电流纠偏切除" : 0)+" ")])]),_c('div',{staticClass:"shadow shadow5 flex"},[_c('div',{staticClass:"column1"},[_vm._v("H08-DLTC")]),_c('div',{staticClass:"column2",on:{"click":function($event){return _vm.toIpt(
                _vm.infoList.DLLB,
                'H08-DLTC',
                'DLLB',
                _vm.infoList.DLLB_node_string
              )}}},[_vm._v(" "+_vm._s("DLLB" in _vm.infoList ? _vm.infoList.DLLB : 0)+" ")])])]),_c('div',{staticClass:"six"},[_c('div',{staticClass:"PZ1"},[_vm._v("PZ1")]),_c('div',{staticClass:"PZ2"},[_vm._v("PZ2")]),_c('div',{staticClass:"buttonx button1",style:({
            background:
              'MAN17' || 'MAN18' || 'MAN26' || 'MAN30' in _vm.infoList
                ? _vm.infoList.MAN17.RM == 1 ||
                  _vm.infoList.MAN18.RM == 1 ||
                  _vm.infoList.MAN26.RM == 1 ||
                  _vm.infoList.MAN30.RM == 1
                  ? '#2AFC30'
                  : 'red'
                : 'red',
          }),on:{"click":function($event){return _vm.toCompon(0, 'CFB_MANECF', 4)}}},[_vm._v(" A ")]),_c('div',{staticClass:"PZ3"},[_vm._v("PZ3")]),_c('div',{staticClass:"PZ4"},[_vm._v("PZ4")])])]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"shadow shadow1 float1 flex"},[_c('div',{staticClass:"column1"},[_vm._v("H09-A01")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
              2,
              'KF_ECFBP_B',
              _vm.infoList.KF_ECFBP_B_node_string,
              'KF_ECFBP_BVV1',
              '二次风机变频反馈'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KF_ECFBP_B,
              'KF_ECFBP_B',
              _vm.infoList.KF_ECFBP_B_node_string,
              'KF_ECFBP_BVV1'
            )}}},[_vm._v(" "+_vm._s("KF_ECFBP_B" in _vm.infoList ? _vm.infoList.KF_ECFBP_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float2 flex"},[_c('div',{staticClass:"column1"},[_vm._v("H09-A02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
              2,
              'KF_ECFBP2_B',
              _vm.infoList.KF_ECFBP2_B_node_string,
              'KF_ECFBP2_BVV1',
              '2#二次风变频反馈'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KF_ECFBP2_B,
              'KF_ECFBP2_B',
              _vm.infoList.KF_ECFBP2_B_node_string,
              'KF_ECFBP2_BVV1'
            )}}},[_vm._v(" "+_vm._s("KF_ECFBP2_B" in _vm.infoList ? _vm.infoList.KF_ECFBP2_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float3 flex"},[_c('div',{staticClass:"column1"},[_vm._v("H10-A01")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
              2,
              'KF_ECFDB_B',
              _vm.infoList.KF_ECFDB_B_node_string,
              'KF_ECFDB_BVV1',
              '二次风机挡板反馈'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KF_ECFDB_B,
              'KF_ECFDB_B',
              _vm.infoList.KF_ECFDB_B_node_string,
              'KF_ECFDB_BVV1'
            )}}},[_vm._v(" "+_vm._s("KF_ECFDB_B" in _vm.infoList ? _vm.infoList.KF_ECFDB_B : 0)+" ")])]),_c('div',{staticClass:"shadow shadow1 float4 flex"},[_c('div',{staticClass:"column1"},[_vm._v("H10-A02")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toCompon(
              2,
              'KF_ECFDB2_B',
              _vm.infoList.KF_ECFDB2_B_node_string,
              'KF_ECFDB2_BVV1',
              '2#二次风挡板反馈'
            )},"dblclick":function($event){return _vm.Cclick(
              _vm.infoList.KF_ECFDB2_B,
              'KF_ECFDB2_B',
              _vm.infoList.KF_ECFDB2_B_node_string,
              'KF_ECFDB2_BVV1'
            )}}},[_vm._v(" "+_vm._s("KF_ECFDB2_B" in _vm.infoList ? _vm.infoList.KF_ECFDB2_B : 0)+" ")])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"node1":_vm.node1,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e(),(_vm.manyManual)?_c('manyManual',{attrs:{"titname":_vm.Manualtitname,"numM":_vm.Manualnode,"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e(),(_vm.AirSoft)?_c('AirSoft',{attrs:{"infoList":_vm.infoList},on:{"sendStatus":_vm.isClose}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v("二次风优化控制模型")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loading"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"num1"},[_vm._v("0")]),_c('div',{staticClass:"num2"},[_vm._v("100")])]),_c('div',{staticClass:"loading_rate"},[_c('div',{staticClass:"rate_context"})])])}]

export { render, staticRenderFns }