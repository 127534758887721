var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"draw",rawName:"v-draw"}],staticClass:"total drag",attrs:{"id":"CfbAirSoft"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"title-hang flex"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.projectData.project)+"_风量软测量")]),_c('div',{staticClass:"icon",on:{"click":_vm.closeCompon}})])]),_vm._m(0),_c('div',{staticClass:"context flex"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"one"},[_c('div',{staticClass:"word"},[_vm._v("一次风量")]),_c('div',{staticClass:"table1"},[_vm._m(1),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量K值1")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.FLXS,
                  '软测量K值1',
                  'FLXS',
                  _vm.infoList.FLXS_node_string
                )}}},[_vm._v(" "+_vm._s("FLXS" in _vm.infoList ? _vm.infoList.FLXS : 0)+" ")])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量C值1")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.FLCS,
                  '软测量C值1',
                  'FLCS',
                  _vm.infoList.FLCS_node_string
                )}}},[_vm._v(" "+_vm._s("FLCS" in _vm.infoList ? _vm.infoList.FLCS : 0)+" ")])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量结果1")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JSFL11',
                  _vm.infoList.JSFL11_node_string,
                  'JSFL11VV1',
                  '1#一次风计算风量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.JSFL11,
                  'JSFL11',
                  _vm.infoList.JSFL11_node_string,
                  'JSFL11VV1'
                )}}},[_vm._v(" "+_vm._s("JSFL11" in _vm.infoList ? _vm.infoList.JSFL11 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("实际测量1")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'FT104D_B',
                  _vm.infoList.FT104D_B_node_string,
                  'FT104D_BVV1',
                  '1#一次风量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.FT104D_B,
                  'FT104D_B',
                  _vm.infoList.FT104D_B_node_string,
                  'FT104D_BVV1'
                )}}},[_vm._v(" "+_vm._s("FT104D_B" in _vm.infoList ? _vm.infoList.FT104D_B : 0)+" ")])])]),_c('div',{staticClass:"table2"},[_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("预测风量")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JSFL',
                  _vm.infoList.JSFL_node_string,
                  'JSFLVV1',
                  '一次风量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.JSFL,
                  'JSFL',
                  _vm.infoList.JSFL_node_string,
                  'JSFLVV1'
                )}}},[_vm._v(" "+_vm._s("JSFL" in _vm.infoList ? _vm.infoList.JSFL : 0)+" ")])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量K值2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.FLXS2,
                  '软测量K值2',
                  'FLXS2',
                  _vm.infoList.FLXS2_node_string
                )}}},[_vm._v(" "+_vm._s("FLXS2" in _vm.infoList ? _vm.infoList.FLXS2 : 0)+" ")])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量C值2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.FLCS2,
                  '软测量C值2',
                  'FLCS2',
                  _vm.infoList.FLCS2_node_string
                )}}},[_vm._v(" "+_vm._s("FLCS2" in _vm.infoList ? _vm.infoList.FLCS2 : 0)+" ")])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量结果2")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JSFL12',
                  _vm.infoList.JSFL12_node_string,
                  'JSFL12VV1',
                  '2#一次风计算风量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.JSFL12,
                  'JSFL12',
                  _vm.infoList.JSFL12_node_string,
                  'JSFL12VV1'
                )}}},[_vm._v(" "+_vm._s("JSFL12" in _vm.infoList ? _vm.infoList.JSFL12 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("实际测量2")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'FT107D_B',
                  _vm.infoList.FT107D_B_node_string,
                  'FT107D_BVV1',
                  '2#一次风量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.FT107D_B,
                  'FT107D_B',
                  _vm.infoList.FT107D_B_node_string,
                  'FT107D_BVV1'
                )}}},[_vm._v(" "+_vm._s("FT107D_B" in _vm.infoList ? _vm.infoList.FT107D_B : 0)+" ")])])])]),_c('div',{staticClass:"two"},[_c('div',{staticClass:"word"},[_vm._v("二次风量")]),_c('div',{staticClass:"table1"},[_vm._m(2),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量K值1")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.FLXS3,
                  '软测量K值1',
                  'FLXS3',
                  _vm.infoList.FLXS3_node_string
                )}}},[_vm._v(" "+_vm._s("FLXS3" in _vm.infoList ? _vm.infoList.FLXS3 : 0)+" ")])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量C值1")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.FLCS3,
                  '软测量C值1',
                  'FLCS3',
                  _vm.infoList.FLCS3_node_string
                )}}},[_vm._v(" "+_vm._s("FLCS3" in _vm.infoList ? _vm.infoList.FLCS3 : 0)+" ")])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量结果1")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JSFL21',
                  _vm.infoList.JSFL21_node_string,
                  'JSFL21VV1',
                  '1#二次风计算风量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.JSFL21,
                  'JSFL21',
                  _vm.infoList.JSFL21_node_string,
                  'JSFL21VV1'
                )}}},[_vm._v(" "+_vm._s("JSFL21" in _vm.infoList ? _vm.infoList.JSFL21 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("实际测量1")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'FT105D_B',
                  _vm.infoList.FT105D_B_node_string,
                  'FT105D_BVV1',
                  '1#二次风量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.FT105D_B,
                  'FT105D_B',
                  _vm.infoList.FT105D_B_node_string,
                  'FT105D_BVV1'
                )}}},[_vm._v(" "+_vm._s("FT105D_B" in _vm.infoList ? _vm.infoList.FT105D_B : 0)+" ")])])]),_c('div',{staticClass:"table2"},[_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("预测风量")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'ECFL',
                  _vm.infoList.ECFL_node_string,
                  'ECFLVV1',
                  '二次风量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.ECFL,
                  'ECFL',
                  _vm.infoList.ECFL_node_string,
                  'ECFLVV1'
                )}}},[_vm._v(" "+_vm._s("ECFL" in _vm.infoList ? _vm.infoList.ECFL : 0)+" ")])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量K值2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.FLXS4,
                  '软测量K值2',
                  'FLXS4',
                  _vm.infoList.FLXS4_node_string
                )}}},[_vm._v(" "+_vm._s("FLXS4" in _vm.infoList ? _vm.infoList.FLXS4 : 0)+" ")])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量C值2")]),_c('div',{staticClass:"column3",on:{"click":function($event){return _vm.toIpt(
                  _vm.infoList.FLCS4,
                  '软测量C值2',
                  'FLCS4',
                  _vm.infoList.FLCS4_node_string
                )}}},[_vm._v(" "+_vm._s("FLCS4" in _vm.infoList ? _vm.infoList.FLCS4 : 0)+" ")])]),_c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("软测量结果2")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'JSFL22',
                  _vm.infoList.JSFL22_node_string,
                  'JSFL22VV1',
                  '2#二次风计算风量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.JSFL22,
                  'JSFL22',
                  _vm.infoList.JSFL22node_string,
                  'JSFL22VV1'
                )}}},[_vm._v(" "+_vm._s("JSFL22" in _vm.infoList ? _vm.infoList.JSFL22 : 0)+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"column2 left"},[_vm._v("实际测量2")]),_c('div',{staticClass:"column4",on:{"click":function($event){return _vm.toCompon(
                  2,
                  'FT106D_B',
                  _vm.infoList.FT106D_B_node_string,
                  'FT106D_BVV1',
                  '2#二次风量'
                )},"dblclick":function($event){return _vm.Cclick(
                  _vm.infoList.FT106D_B,
                  'FT106D_B',
                  _vm.infoList.FT106D_B_node_string,
                  'FT106D_BVV1'
                )}}},[_vm._v(" "+_vm._s("FT106D_B" in _vm.infoList ? _vm.infoList.FT106D_B : 0)+" ")])])])])])]),_c('inputVal',{ref:"inputVal"}),(_vm.isHshow)?_c('Historical',{attrs:{"historyname":_vm.historyname,"node":_vm.node,"Lkname":_vm.Lkname,"chName":_vm.chName,"infoList":_vm.infoList},on:{"sendStatus":function($event){_vm.isHshow = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main_title"},[_vm._v("风量软测量")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column1 left"},[_vm._v("测量方式")]),_c('div',{staticClass:"column1"},[_vm._v("实际测量")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom flex"},[_c('div',{staticClass:"column1 left"},[_vm._v("测量方式")]),_c('div',{staticClass:"column1"},[_vm._v("实际测量")])])}]

export { render, staticRenderFns }